import styled from 'styled-components'

import { ElipseShape } from '../../assets'

export const Hero = styled.div``

export const Cover = styled.div`
  width: 100%;
  height: 398px;
  overflow: hidden;
  position: relative;
  padding: 0 1rem;
`

export const CoverImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 3;
`

export const Content = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 32px 16px 40px 16px;
`

export const Elipse = styled(ElipseShape)`
  position: absolute;
  right: 0;
  bottom: -140px;
  z-index: -1;
`

export const Title = styled.h1`
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  text-align: center;
  white-space: pre-line;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
`

export const Message = styled.p`
  font-family: ${(props) => props.theme.typography.body1.fontFamily};
  font-weight: ${(props) => props.theme.typography.body1.fontWeight};
  font-size: ${(props) => props.theme.typography.body1.fontSize};
  line-height: ${(props) => props.theme.typography.body1.lineHeight};
  letter-spacing: ${(props) => props.theme.typography.body1.letterSpacing};
  color: ${(props) => props.theme.typography.body1.color};
  text-align: center;
  width: 100%;
  min-width: 17rem;
  max-width: 25rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 2.5rem;
  padding: 0 1rem;
`
