import type { GetStaticProps, NextPage } from 'next'
import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { HomePage } from '~modules/home/pages/Home.page'

export const getStaticProps: GetStaticProps = async ({ locale = 'es-MX' }) => {
  return {
    props: {
      messages: (await import(`../locales/${locale}.js`)).default,
    },
  }
}

const IndexPage: NextPage = () => {
  const t = useTranslations('home')
  const router = useRouter()

  useEffect(() => {
    router.prefetch('/registro')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <NextSeo title={t('metaTitle')} description={t('metaDescription')} />
      <HomePage />
    </>
  )
}

export default IndexPage
