import { Button } from '@mymoons/ui-library'
import { useTranslations } from 'next-intl'
import { FC } from 'react'

import { RadialGradient } from '~components/RadialGradient'
import { getS3Image } from '~utilities/getS3Image.utilities'

0
import * as Styled from './Hero.styled'
export interface HeroProps {
  title: string
  message: string
}

export const Hero: FC<HeroProps> = ({ title, message }) => {
  const t = useTranslations('home')

  return (
    <Styled.Hero>
      <Styled.Cover>
        <Styled.CoverImage
          alt={t('altCover')}
          {...getS3Image('home-hero')}
          width="347"
          height="337"
        />
        <RadialGradient width={492} height={488} left={-195} top={-262} color={'#ffd7ba'} />
        <RadialGradient width={402} height={411} bottom={-67} right={-180} color={'#B9C1F4'} />
      </Styled.Cover>
      <Styled.Content>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Message>{message}</Styled.Message>
        <Button
          href="/registro"
          color="red"
          label={t('start')}
          size="medium"
          variant="filled"
          fullWidth
        />
        <Styled.Elipse />
      </Styled.Content>
    </Styled.Hero>
  )
}
